<template>
    <voffice-popup-box :trigger-id="triggerId"
        class="full-xxs datepickerpopup"
        id="datepicker"
        :apply-label="applyLabel"
        :close-btn-disabled="closeBtnDisabled"
        @closed="onClosed">

        <div class="datepicker-trigger">

            <airbnb-style-datepicker :trigger-element-id="triggerId"
                :close-after-select="false"
                :inline="true"
                :show-shortcuts-menu-trigger="false"
                :show-action-buttons="false"
                :offset-y="10"
                :mode="'range'"
                :fullscreen-mobile="false"
                :disabled-dates="disabledDays"
                :customized-dates="calClasses"
                :min-date="minDate"
                :end-date="maxDate"
                :date-one="from"
                :date-two="till"
                @closed="onClosed"
                :mobile-header="mobileHeader"
                @date-one-selected="onFromSelected"
                @date-two-selected="onTillSelected" />

            <div>
                <div class="pickerhint">
                    <div v-if="!from">
                        Bitte wählen Sie Ihren Anreisetag aus.
                    </div>

                    <div v-if="from && ! till">
                        Bitte wählen Sie jetzt Ihren Abreisetag aus.
                    </div>

                    <div v-if="from && till">
                        Wählen Sie aus, wie viele Nächte Sie bleiben möchten.
                    </div>
                </div>
                <div class="datepicker-ext-row"
                    v-if="possibleNights">
                    <span>{{voMsg('min.label')}}</span>
                    <select v-model="minNights">
                        <option v-for="option in possibleNights"
                            v-bind:value="option.value">
                            {{ option.text }}
                        </option>
                    </select>
                    <span>{{voMsg('max.label')}}</span>
                    <select v-model="maxNights">
                        <option v-for="option in possibleNights"
                            v-bind:value="option.value">
                            {{ option.text }}
                        </option>
                    </select>

                </div>
            </div>

        </div>
    </voffice-popup-box>

</template>

<script>
    import {
    	isBlank
    }
    from 'voUtils/tools.js';
    import V from 'voUtils/V.js'


    import DateEx from 'voUtils/DateEx.js'

    export default {
    	voVueComponent: 'voffice-daterange-ext',
    	voMsgCodes: [
    		'weekdayslist.short',
    		'monthlist.full',
    		'weekdayslist',
    		'night.pl',
    		'min.label',
    		'max.label',
    	],
    	data() {
    		return {
    			needTill: false,
    			nextArrivalDate: undefined,
    			disabledArrivalDays: [],
    			disabledDepartureDays: [],
    			minNights: undefined,
    			maxNights: undefined,
    			calClasses: [],
    			cal: undefined,
    		}
    	},
    	props: {
    		from: String,
    		till: String,
    		nights_min: Number,
    		nights_max: Number,
    		triggerId: String,
    		fullscreenMobile: Boolean,
    		applyLabel: String,
    	},
    	computed: {
    		disabledDays: function() {
    			return this.needTill ? this.disabledDepartureDays : this.disabledArrivalDays
    		},
    		closeBtnDisabled: function() {
    			return !this.from || !this.till
    		},
    		possibleNights: function() {
    			if (this.from && this.till) {
    				let days = DateEx.getDays(this.till, this.from)
    				let result = []
    				for (let i = 1; i <= days; i++) {
    					result.push({
    						value: i,
    						text: i + ' ' + this.voMsgPl('night.pl', i)
    					})
    				}
    				//console.log("result", result, days)
    				return result
    			} else {
    				return undefined
    			}

    		},
    		mobileHeader: function() {
    			if (this.needTill) {
    				return this.voMsg("daterange.till");
    			} else {
    				return this.voMsg("daterange.from");
    			}
    		},
    		minDate: function() {
    			if (this.needTill) {
    				return DateEx.plusDays(this.from, 1);
    			} else {
    				return DateEx.interfaceFormat(DateEx.getToday());
    			}

    		},
    		maxDate: function() {
    			if (this.needTill) {
    				return this.nextArrivalDate;
    			} else {
    				return undefined;
    			}

    		}

    	},

    	methods: {
    		onClosed: function() {

    			if (this.$parent.searchData.from && !this.$parent.searchData.till) {
    				this.$parent.searchData.till = DateEx.interfaceFormat(DateEx.plusDays(this.$parent.searchData.from, 7));
    			}

    			setTimeout(() => {
    				this.$emit('closed');
    			}, 50);
    		},
    		onFromSelected: function(d) {
    			this.needTill = true;
    			this.$emit('update:from', d);
    			this.$emit('update:till', undefined);


    		},
    		onTillSelected: function(d) {
    			this.needTill = isBlank(d) && this.from;
    			this.$emit('update:till', d);
    			setTimeout(() => {
    				this.$emit('on-tilldate-selected');
    			}, 50);
    		},
    		updateSelectedNights: function() {
    			if (this.from && this.till) {
    				var diffDays = DateEx.getDays(this.till, this.from);
    				//console.log('diffDays::', diffDays);
    				//console.log('this.minNights::', this.minNights);
    				//console.log('this.maxNights::', this.maxNights);
    				if (!this.nights_min) {
    					this.minNights = diffDays;
    				} else {
    					if (diffDays < this.nights_min) {
    						this.minNights = diffDays;
    					} else {
    						this.minNights = this.nights_min;
    					}
    				}
    				if (!this.nights_max) {
    					this.maxNights = diffDays;
    				} else {
    					if (diffDays < this.nights_max) {
    						this.maxNights = diffDays;
    					} else {
    						this.maxNights = this.nights_max
    					}
    				}
    			}
    		}
    	},
    	watch: {
    		till: function() {
    			this.updateSelectedNights()
    		},
    		minNights: function() {
    			this.$emit('update:nights_min', this.minNights);
    			if (this.maxNights && this.maxNights < this.minNights) {
    				this.maxNights = this.minNights
    			}
    		},
    		maxNights: function() {
    			this.$emit('update:nights_max', this.maxNights);
    			if (this.minNights && this.minNights > this.maxNights) {
    				this.minNights = this.maxNights
    			}
    		},


    	}
    }
</script>

<style scoped>
    .asd__day {
    	opacity: 1 !important;
    }

    .asd__day--disabled,
    .asd__day--empty {
    	opacity: 1 !important;
    }

    .pickerhint {
    	margin-top: 10px;
    	text-align: center;
    	font-size: 16px;
    }
</style>