var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "voffice-popup-box",
    {
      staticClass: "full-xxs datepickerpopup",
      attrs: {
        "trigger-id": _vm.triggerId,
        id: "datepicker",
        "apply-label": _vm.applyLabel,
        "close-btn-disabled": _vm.closeBtnDisabled
      },
      on: { closed: _vm.onClosed }
    },
    [
      _c(
        "div",
        { staticClass: "datepicker-trigger" },
        [
          _c("airbnb-style-datepicker", {
            attrs: {
              "trigger-element-id": _vm.triggerId,
              "close-after-select": false,
              inline: true,
              "show-shortcuts-menu-trigger": false,
              "show-action-buttons": false,
              "offset-y": 10,
              mode: "range",
              "fullscreen-mobile": false,
              "disabled-dates": _vm.disabledDays,
              "customized-dates": _vm.calClasses,
              "min-date": _vm.minDate,
              "end-date": _vm.maxDate,
              "date-one": _vm.from,
              "date-two": _vm.till,
              "mobile-header": _vm.mobileHeader
            },
            on: {
              closed: _vm.onClosed,
              "date-one-selected": _vm.onFromSelected,
              "date-two-selected": _vm.onTillSelected
            }
          }),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "pickerhint" }, [
              !_vm.from
                ? _c("div", [
                    _vm._v(
                      "\n                    Bitte wählen Sie Ihren Anreisetag aus.\n                "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.from && !_vm.till
                ? _c("div", [
                    _vm._v(
                      "\n                    Bitte wählen Sie jetzt Ihren Abreisetag aus.\n                "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.from && _vm.till
                ? _c("div", [
                    _vm._v(
                      "\n                    Wählen Sie aus, wie viele Nächte Sie bleiben möchten.\n                "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.possibleNights
              ? _c("div", { staticClass: "datepicker-ext-row" }, [
                  _c("span", [_vm._v(_vm._s(_vm.voMsg("min.label")))]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.minNights,
                          expression: "minNights"
                        }
                      ],
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.minNights = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.possibleNights, function(option) {
                      return _c(
                        "option",
                        { domProps: { value: option.value } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(option.text) +
                              "\n                    "
                          )
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.voMsg("max.label")))]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.maxNights,
                          expression: "maxNights"
                        }
                      ],
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.maxNights = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.possibleNights, function(option) {
                      return _c(
                        "option",
                        { domProps: { value: option.value } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(option.text) +
                              "\n                    "
                          )
                        ]
                      )
                    }),
                    0
                  )
                ])
              : _vm._e()
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }